const metric = {
  "N-mm3": {
    name: {
      singular: 'newton per cubic millimeter',
      plural: 'newtons per cubic millimites',
    },
    to_anchor: 1,
  },
  "kgf-cm3": {
    name: {
      singular: 'kilogram-force per cubic centimeter',
      plural: 'kilograms-force per cubic centimeter',
    },
    to_anchor: 9.80665 / 1000,
  },
};

const imperial = {
  pci: {
    name: {
      singular: 'pound per cubic inch',
      plural: 'pounds per cubic inch',
    },
    to_anchor: 1,
  },
};

const measure = {
  systems: {
    metric,
    imperial,
  },
  anchors: {
    metric: {
      imperial: {
        ratio: (25.4 ** 3) / 4.448222,
      },
    },
    imperial: {
      metric: {
        ratio: 4.448222 / (25.4 ** 3),
      },
    },
  },
};

export default measure;
