const metric = {
  "N/m3": {
    name: {
      singular: 'newton per cubic meter',
      plural: 'newtons per cubic meter',
    },
    to_anchor: 1 / 1000,
  },
  "kN/m3": {
    name: {
      singular: 'kilonewton per cubicmeter',
      plural: 'kilonewtons per cubic meter',
    },
    to_anchor: 1,
  },
  "kgf/m3": {
    name: {
      singular: 'kilogram-force per cubic meter',
      plural: 'kilograms-force per cubic meter',
    },
    to_anchor: 9.81 / 1000,
  },
};
const imperial = {
  "lbf/yd3": {
    name: {
      singular: 'pound force per cubic yard',
      plural: 'pounds force per cubic yard',
    },
    to_anchor: 1,
  },
};
const measure = {
  systems: {
    metric,
    imperial,
  },
  anchors: {
    metric: {
      imperial: {
        ratio: 0.171879,
      },
    },
    imperial: {
      metric: {
        ratio: 1 / 0.171879,
      },
    },
  },
};
export default measure;
