// FIXME: VERIFICARE LE CONVERSIONI
const metric = {
  "N/m": {
    name: {
      singular: 'newton per meter',
      plural: 'newtons per meter',
    },
    to_anchor: 1 / 1000,
  },
  "kN/m": {
    name: {
      singular: 'kilonewton per meter',
      plural: 'kilonewtons per meter',
    },
    to_anchor: 1,
  },
  "tf/m": {
    name: {
      singular: 'tonne force per meter',
      plural: 'tonnes force per meter',
    },
    to_anchor: 9.80665,
  },
  "kgf/cm": {
    name: {
      singular: 'tonne force per meter',
      plural: 'tonnes force per meter',
    },
    to_anchor: 9.80665 / 1000 * 100,
  }
};
const imperial = {
  "lbf/in": {
    name: {
      singular: 'pound force per inch',
      plural: 'pounds force per inch',
    },
    to_anchor: 12 / 1000,
  },
  "kip/ft": {
    name: {
      singular: 'kilopound per foot',
      plural: 'kilopounds per foot',
    },
    to_anchor: 1,
  },
};
const measure = {
  systems: {
    metric,
    imperial,
  },
  anchors: {
    metric: {
      imperial: {
        ratio: 1 / 14.5939029372063648294,
      },
    },
    imperial: {
      metric: {
        ratio: 14.5939029372063648294,
      },
    },
  },
};
export default measure;
