const metric = {
  N: {
    name: {
      singular: 'Newton',
      plural: 'Newtons',
    },
    to_anchor: 1,
  },
  kN: {
    name: {
      singular: 'Kilonewton',
      plural: 'Kilonewtons',
    },
    to_anchor: 1000,
  },
  kgf: {
    name: {
      singular: 'Kilogram-force',
      plural: 'Kilograms-force',
    },
    to_anchor: 9.806650,
  },
  tf: {
    name: {
      singular: 'Tonne-force',
      plural: 'Tonnes-force'
    },
    to_anchor: 9806.650,
  }
};

const imperial = {
  lbf: {
    name: {
      singular: 'Pound-force',
      plural: 'Pounds-force',
    },
    to_anchor: 1,
  },
  kip: {
    name: {
      singular: 'Kilopound',
      plural: 'Kilopounds'
    },
    to_anchor: 1000,
  },
};

const measure = {
  systems: {
    metric,
    imperial,
  },
  anchors: {
    metric: {
      imperial: {
        ratio: 1 / 4.44822,
      },
    },
    imperial: {
      metric: {
        ratio: 4.44822,
      },
    },
  },
};

export default measure;
