import UnitConversions from '@/components/shared/UnitConversions'
import useUnitsStore from '@/stores/useUnitsStore'

export const quantities = {
  "plan length": {
    basic: "m",
    SI: [
      { unit: "mm", jsx: <span>mm</span>, unicode: "mm", latex: "mm" },
      { unit: "cm", jsx: <span>cm</span>, unicode: "cm", latex: "cm" },
      { unit: "m", jsx: <span>m</span>, unicode: "m", latex: "m" },
    ],
    USC: [
      { unit: "in", jsx: <span>in.</span>, unicode: "in.", latex: "in." },
      { unit: "ft", jsx: <span>ft.</span>, unicode: "ft.", latex: "ft." },
    ]
  },
  "section length": {
    basic: "mm",
    SI: [
      { unit: "mm", jsx: <span>mm</span>, unicode: "mm", latex: "mm" },
      { unit: "cm", jsx: <span>cm</span>, unicode: "cm", latex: "cm" },
      { unit: "m", jsx: <span>m</span>, unicode: "m", latex: "m" },
    ],
    USC: [
      { unit: "in", jsx: <span>in.</span>, unicode: "in.", latex: "in." },
      { unit: "ft", jsx: <span>ft.</span>, unicode: "ft.", latex: "ft." },
    ]
  },
  "plan area": {
    basic: "m2",
    SI: [
      { unit: "mm2", jsx: <span>mm<sup>2</sup></span>, unicode: "mm²", latex: "mm^2" },
      { unit: "cm2", jsx: <span>cm<sup>2</sup></span>, unicode: "cm²", latex: "cm^2" },
      { unit: "m2", jsx: <span>m<sup>2</sup></span>, unicode: "m²", latex: "m^2" },
    ],
    USC: [
      { unit: "in2", jsx: <span>in<sup>2</sup></span>, unicode: "in²", latex: "in^2" },
      { unit: "ft2", jsx: <span>ft<sup>2</sup></span>, unicode: "ft²", latex: "ft^2" },
    ]
  },
  "section area": {
    basic: "mm2",
    SI: [
      { unit: "mm2", jsx: <span>mm<sup>2</sup></span>, unicode: "mm²", latex: "mm^2" },
      { unit: "cm2", jsx: <span>cm<sup>2</sup></span>, unicode: "cm²", latex: "cm^2" },
      { unit: "m2", jsx: <span>m<sup>2</sup></span>, unicode: "m²", latex: "m^2" },
    ],
    USC: [
      { unit: "in2", jsx: <span>in<sup>2</sup></span>, unicode: "in²", latex: "in^2" },
      { unit: "ft2", jsx: <span>ft<sup>2</sup></span>, unicode: "ft²", latex: "ft^2" },
    ]
  },
  "torque": {
    basic: "kNm",
    SI: [
      { unit: "Nm", jsx: <span>Nm</span>, unicode: "Nm", latex: "Nm" },
      { unit: "kNm", jsx: <span>kNm</span>, unicode: "kNm", latex: "kNm" },
      { unit: "kgf-m", jsx: <span>kg<sub>f</sub>m</span>, unicode: "kgf⋅m", latex: "kg_fm" },
      { unit: "tf-m", jsx: <span>t<sub>f</sub>m</span>, unicode: "tf⋅m", latex: "t_fm" },
    ],
    USC: [
      { unit: "lbf-ft", jsx: <span>lb<sub>f</sub>ft</span>, unicode: "lbf⋅ft", latex: "lb_fft" },
      { unit: "kip-ft", jsx: <span>kip⋅ft</span>, unicode: "kip⋅ft", latex: "kip⋅ft" },
    ]
  },
  "force": {
    basic: "kN",
    SI: [
      { unit: "N", jsx: <span>N</span>, unicode: "N", latex: "N" },
      { unit: "kN", jsx: <span>kN</span>, unicode: "kN", latex: "kN" },
      { unit: "kgf", jsx: <span>kg<sub>f</sub></span>, unicode: "kgf", latex: "kg_f" },
      { unit: "tf", jsx: <span>t<sub>f</sub></span>, unicode: "tf", latex: "t_f" },
    ],
    USC: [
      { unit: "lbf", jsx: <span>lb<sub>f</sub></span>, unicode: "lbf", latex: "lb_f" },
      { unit: "kip", jsx: <span>kip</span>, unicode: "kip", latex: "kip" },
    ]
  },
  "force per length": {
    basic: "kN/m",
    SI: [
      { unit: "kN/m", jsx: <span>kN/m</span>, unicode: "kN/m", latex: "kN/m" },
      { unit: "tf/m", jsx: <span>t<sub>f</sub>/m</span>, unicode: "tf/m", latex: "t_f/m" },
      { unit: "kgf/cm", jsx: <span>kg<sub>f</sub>/cm</span>, unicode: "kgf/cm", latex: "kg_f/cm" },
    ],
    USC: [
      { unit: "kip/ft", jsx: <span>kip/ft</span>, unicode: "kip/ft", latex: "kip/ft" },
      { unit: "lbf/in", jsx: <span>lbf/in</span>, unicode: "lbf/in", latex: "lbf/in" },
    ]
  },
  "force per area": {
    basic: "kPa",
    SI: [
      { unit: "kPa", jsx: <span>kN/m<sup>2</sup></span>, unicode: "kN/m²", latex: "kN/m^2" },
      { unit: "tf/m2", jsx: <span>t<sub>f</sub>/m<sup>2</sup></span>, unicode: "tf/m²", latex: "t_f/m^2" },
      {
        unit: "kgf/cm2", jsx: <span>kg<sub>f</sub>/cm<sup>2</sup></span>, unicode: "kgf/cm²", latex: "kg_f/cm^2"
      },
    ],
    USC: [
      { unit: "kip/ft2", jsx: <span>kip/ft<sup>2</sup></span>, unicode: "kip/ft²", latex: "kip/ft^2" },
      { unit: "psi", jsx: <span>psi</span>, unicode: "psi", latex: "psi" },
    ]
  },
  "pressure": {
    basic: "kPa",
    SI: [
      { unit: "kPa", jsx: <span>kPa</span>, unicode: "kPa", latex: "kPa" },
      { unit: "bar", jsx: <span>bar</span>, unicode: "bar", latex: "bar" },
    ],
    USC: [
      { unit: "psi", jsx: <span>psi</span>, unicode: "psi", latex: "psi" },
    ]
  },
  "stress": {
    basic: "MPa",
    SI: [
      { unit: "MPa", jsx: <span>MPa</span>, unicode: "MPa", latex: "MPa" },
      { unit: "kgf/cm2", jsx: <span>kg<sub>f</sub>/cm<sup>2</sup></span>, unicode: "kgf/cm²", latex: "kg_f/cm^2" },
    ],
    USC: [
      { unit: "psi", jsx: <span>psi</span>, unicode: "psi", latex: "psi" },
      { unit: "ksi", jsx: <span>ksi</span>, unicode: "ksi", latex: "ksi" },
    ]
  },
  "elastic modulus": {
    basic: "MPa",
    SI: [
      { unit: "MPa", jsx: <span>MPa</span>, unicode: "MPa", latex: "MPa" },
      { unit: "GPa", jsx: <span>GPa</span>, unicode: "GPa", latex: "GPa" },
      { unit: "kgf/cm2", jsx: <span>kg<sub>f</sub>/cm<sup>2</sup></span>, unicode: "kgf/cm²", latex: "kg_f/cm^2" },
    ],
    USC: [
      { unit: "psi", jsx: <span>psi</span>, unicode: "psi", latex: "psi" },
      { unit: "ksi", jsx: <span>ksi</span>, unicode: "ksi", latex: "ksi" },
    ]
  },
  "dosage": {
    basic: "kgf/m3",
    SI: [
      { unit: "kgf/m3", jsx: <span>kg<sub>f</sub>/m<sup>3</sup></span>, unicode: "kgf/m³", latex: "kg_f/m^3" },
    ],
    USC: [
      { unit: "lbf/yd3", jsx: <span>lb<sub>f</sub>/yd<sup>3</sup></span>, unicode: "lbf/yd³", latex: "lb_f/yd^3" },
    ]
  },
  "subgrade reaction modulus": {
    basic: "N-mm3",
    SI: [
      { unit: "N-mm3", jsx: <span>N/mm<sup>3</sup></span>, unicode: "N/mm³", latex: "N/mm^3" },
      { unit: "kgf-cm3", jsx: <span>kg<sub>f</sub>/cm<sup>3</sup></span>, unicode: "kgf/cm³", latex: "kg_f/cm^3" },
    ],
    USC: [
      { unit: "pci", jsx: <span>pci</span>, unicode: "pci", latex: "pci" },
    ]
  },
  "temperature": {
    basic: "C",
    SI: [
      { unit: "C", jsx: <span>°C</span>, unicode: "°C", latex: "°C" },
    ],
    USC: [
      { unit: "F", jsx: <span>°F</span>, unicode: "°F", latex: "°F" },
    ]
  }
}

function giveUnits(quantity, unit) {
  const q = quantities[quantity];
  const selectedUnit = q.SI.filter(s => s.unit === unit)[0] || q.USC.filter(s => s.unit === unit)[0]
  return new UnitConversions(q.basic, unit, selectedUnit.jsx, selectedUnit.unicode, selectedUnit.latex)
}

export function choicesSI(quantity) {
  const q = quantities[quantity];
  return q.SI
}

export function choicesUSC(quantity) {
  const q = quantities[quantity];
  return q.USC
}


// FIXME: planeLengrthUnits and sectionLengthUnits will be set equal to one-another
// const planLengthUnits = giveUnits("plan length", "m")
// const sectionLengthUnits = giveUnits("section length", "mm")
// const planAreaUnits = giveUnits("plan area", "m2")
// const sectionAreaUnits = giveUnits("section area", "mm2")
// const loadUnits = giveUnits("force", "kN")
// const loadPerLengthUnits = giveUnits("force per length", "kN/m")
// const loadPerAreaUnits = giveUnits("force per area", "kPa")
// const pressureUnits = giveUnits("pressure", "bar")
// const stressUnits = giveUnits("stress", "MPa")
// const winklerModuliUnits = giveUnits("subgrade reaction modulus", "N-mm3")
// const dosageUnits = giveUnits("dosage", "kgf/m3")

export const percentUnits = new UnitConversions("percent", "percent", <span>%</span>)

// export { /* planLengthUnits, sectionLengthUnits, planAreaUnits, sectionAreaUnits, loadUnits, loadPerLengthUnits, loadPerAreaUnits, pressureUnits, */stressUnits, /* winklerModuliUnits, dosageUnits ,*/ percentUnits }

export function unitConverter(quantity) {
  const units = useUnitsStore.getState().units
  const unit = units[units.currentSystem][quantity]
  return giveUnits(quantity, unit)
}


/*
unitConverter("stress")

const units = {
  SI: {
    "plan length": "m", // "mm" "cm" "m"
    "section length": "mm", // "mm" "cm" "m"
    "plan area": "m2", // "mm2" "cm2" "m2"
    "section area": "mm2", // "mm2" "cm2" "m2"
    "force": "kN", // "N" "kN" "kgf" "tf"
    "force per length": "kN/m", // "kN/m" "tf/m" "kgf/cm"
    "force per area": "kPa", // "kPa" "tf/m2" "kgf/cm2"
    "pressure": "bar", // "kPa" "bar"
    "stress": "MPa", // "MPa" "kgf/cm2"
    "dosage": "kgf/m3", // "kgf/m3"
    "subgrade reaction modulus": "N-mm3", // "N-mm3" "kgf-cm3"
  },
  USC: {
    "plan length": "ft", // "in" "ft"
    "section length": "in", // "in" "ft"
    "plan area": "ft2", // "in2" "ft2"
    "section area": "in2", // "in2" "ft2"
    "force": "lbf", // "lbf" "kip"
    "force per length": "kip/ft", // "lbf/in" "kip/ft"
    "force per area": "kip/ft2", // "psi" "kip/ft2"
    "pressure": "psi", // "psi"
    "stress": "psi", // "psi" "ksi"
    "dosage": "lbf/yd3", // "lbf/yd3"
    "subgrade reaction modulus": "pci", // "pci"
  }
}

*/