"use client"

import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useUnitsStore = create(
  persist(
    (set) => ({
      units: {
        currentSystem: "SI",
        labels: {
          "plan length": "Plan length (e.g. length of beams, distance between uprights)",
          "section length": "Section length (e.g. width of a beam, thickness of a slab)",
          "plan area": "Plan area (e.g. area of slabs)",
          "section area": "Section area (e.g. cross-section of rebars)",
          "torque" : "Torque (e.g. bending moment)",
          "force": "Force (e.g. point loads)",
          "force per length": "Force per unit length (e.g. line loads)",
          "force per area": "Force per unit area (e.g. uniform loads)",
          "pressure": "Pressure of pneumatic wheels",
          "stress": "Stress (e.g. material strengths)",
          "elastic modulus": "Elastic modulus",
          "dosage": "Dosage of fibers",
          "subgrade reaction modulus": "Moduli of subgrade reaction",
          "temperature": "Temperature",
        },
        SI: {
          "plan length": "m",
          "section length": "mm",
          "plan area": "m2",
          "section area": "mm2",
          "torque": "kNm",
          "force": "kN",
          "force per length": "kN/m",
          "force per area": "kPa",
          "pressure": "bar",
          "stress": "MPa",
          "elastic modulus": "GPa",
          "dosage": "kgf/m3",
          "subgrade reaction modulus": "N-mm3",
          "temperature": "C",
        },
        USC: {
          "plan length": "ft",
          "section length": "in",
          "plan area": "ft2",
          "section area": "in2",
          "torque": "kip-ft",
          "force": "lbf",
          "force per length": "kip/ft",
          "force per area": "kip/ft2",
          "pressure": "psi",
          "stress": "psi",
          "elastic modulus": "ksi",
          "dosage": "lbf/yd3",
          "subgrade reaction modulus": "pci",
          "temperature": "F",
        },
      },
      setUnit: (unitType, unitKey, value) =>
        set((state) => ({
          units: {
            ...state.units,
            [unitType]: {
              ...state.units[unitType],
              [unitKey]: value,
            },
          },
        })),

      setCurrentSystem: (system) =>
        set((state) => ({
          units: {
            ...state.units,
            currentSystem: system,
          },
        })),
    }),
    {
      name: 'units-store', // unique name for storage
      getStorage: () => sessionStorage, // switch to sessionStorage
    }
  )
);

export default useUnitsStore;