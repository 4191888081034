const metric = {
    'Nm': {
        name: {
            singular: 'Newton-meter',
            plural: 'Newton-meters',
        },
        to_anchor: 1,
    },
    'kNm': {
        name: {
            singular: 'Kilonewton-meter',
            plural: 'Kilonewton-meters',
        },
        to_anchor: 1000,
    },
    'kgf-m': {
        name: {
            singular: 'Kilogram-force-meter',
            plural: 'Kilogram-force-meters'
        },
        to_anchor: 9.806650,
    },
    'tf-m': {
        name: {
            singular: 'Tonne-force-meter',
            plural: 'Tonne-force-meters'
        },
        to_anchor: 9.806650,
    }
};
const imperial = {
    'lbf-ft': {
        name: {
            singular: 'Pound-force-foot',
            plural: 'Pound-force-feet',
        },
        to_anchor: 1,
    },
    'kip-ft': {
        name: {
            singular: 'Kilopound-foot',
            plural: 'Kilopound-feet',
        },
        to_anchor: 1000,
    },
};
const measure = {
    systems: {
        metric,
        imperial,
    },
    anchors: {
        metric: {
            imperial: {
                ratio: 1 / 1.355818,
            },
        },
        imperial: {
            metric: {
                ratio: 1.355818,
            },
        },
    },
};
export default measure;
