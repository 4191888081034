import configureMeasurements from '@/lib/convert-units'
import allMeasures from '@/lib/convert-units/definitions/all'

class UnitConversions {
  constructor(defaultUnit, currentUnit, JSXcurrentUnit, unicodeCurrentUnit, latexCurrentUnit) {
    this.convert = configureMeasurements(allMeasures)
    this.defaultUnit = defaultUnit
    this.currentUnit = currentUnit
    this.JSXcurrentUnit = JSXcurrentUnit
    this.unicodeCurrentUnit = unicodeCurrentUnit
    this.latexCurrentUnit = latexCurrentUnit
  }

  toCurrentUnit(valueInDefaultUnit) {
    return this.convert(valueInDefaultUnit).from(this.defaultUnit).to(this.currentUnit)
  }

  toDefaultUnit(valueInCurrentUnit) {
    return this.convert(valueInCurrentUnit).from(this.currentUnit).to(this.defaultUnit)
  }

  toCurrentUnitFmt(valueInDefaultUnit, stepInDefaultUnit) {
    const numberOfDigits = Math.max(0, Math.ceil(-Math.log10(this.toCurrentUnit(stepInDefaultUnit))))
    const rounder = 10 ** numberOfDigits
    return Math.round(this.toCurrentUnit(valueInDefaultUnit) * rounder) / rounder
  }

  getText(valueInDefaultUnit, stepInDefaultUnit) {
    return this.toCurrentUnitFmt(valueInDefaultUnit, stepInDefaultUnit) + " " + this.currentUnit
  }

  getUnicode(valueInDefaultUnit, stepInDefaultUnit) {
    return this.toCurrentUnitFmt(valueInDefaultUnit, stepInDefaultUnit) + " " + this.unicodeCurrentUnit
  }

  getLatex(valueInDefaultUnit, stepInDefaultUnit) {
    return this.toCurrentUnitFmt(valueInDefaultUnit, stepInDefaultUnit) + " " + this.latexCurrentUnit
  }

  getJsx(valueInDefaultUnit, stepInDefaultUnit) {
    return (<span>{this.toCurrentUnitFmt(valueInDefaultUnit, stepInDefaultUnit)}&nbsp;{this.JSXcurrentUnit}</span>)
  }
}

export default UnitConversions